/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Pagination, SortDropdown } from '@tovia/man-ui';
import { FormattedMessage } from 'react-intl';
import PaginationModal from 'src/client/components/modals/PaginationModal';
import LoaderWrapper from 'src/client/components/Placeholders/Layouts/GalleriesPlaceholder';

import * as galleriesActions from 'src/client/redux/modules/galleries';
import { SORT_TYPES } from 'src/shared/constants/galleries';
import { setValue } from 'src/client/redux/modules/settings';
import ConnectedViewOptions from '../../ConnectedViewOptions';
import { GalleryCards } from 'src/client/containers/Cards/Gallery/GalleryCards';
import { useSelector, useSettingsSelector } from 'src/client/redux/modules/helpers/useSelector';
import Helmet from 'src/client/containers/Helmet/Helmet';
import urls from 'src/shared/urls';
import { isPrerender } from 'src/client/utils/prerenderDetect';
import { CamsContext } from 'src/client/containers/Cams/CamsContextProvider';

const DEFAULT = 'default';
const PPS_GALLERIES = 'pps';
const PHOTOGRAPHER = 'photographer';
const MODEL = 'model';

const Div = styled.div`
  .options {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;

    .sort-dropdown {
      margin-right: 20px;
    }
  }
`;

type Props = {
  galleryType?: string;
  hideViewOptions?: boolean;
  pageType?: string;
  sortSettingsKey?: string;
  UUID?: string;
};

export default function GalleriesContent(props: Props) {
  const dispatch = useDispatch();
  const load = useCallback((args) => dispatch(galleriesActions.load(args)), [dispatch]);
  const config = useSelector((state) => state.app.config);
  const { domain, name: siteName } = useSelector((state) => state.site);
  const cams = useContext(CamsContext);

  const galleryViewType = useSettingsSelector('listView');
  const sortBySetting = useSettingsSelector<string>('galleriesSorting');

  const { page, photographerName, modelName, tab, sortBy } = useParams<{
    page: string;
    photographerName?: string;
    modelName?: string;
    tab?: string;
    sortBy: string;
  }>();
  const history = useHistory();
  const { pageType = DEFAULT, galleryType = 'GALLERY', UUID = '', sortSettingsKey = 'galleriesSorting' } = props;

  const sort = SORT_TYPES.find((type) => type.id === sortBy);
  const sortByParam = (sort && sortBy) || sortBySetting;
  const pageParam = (!sort && parseInt(sortBy)) || parseInt(page) || 1;

  const { error, galleries, loaded, pageSize, itemCount } = useSelector((state) => state.galleries);

  const loadData = useCallback(() => {
    const params = {
      sortBy: sortByParam,
      first: pageSize,
      galleryType: galleryType.toUpperCase(),
      ...(pageType === PPS_GALLERIES ? { PPSOnly: true } : {}),
      ...(pageType === PHOTOGRAPHER ? { photographerUUID: UUID } : {}),
      ...(pageType === MODEL ? { modelUUID: UUID } : {}),
      page: pageParam,
    };
    load(params);
  }, [galleryType, load, pageParam, pageSize, pageType, sortByParam, UUID]);

  useEffect(() => {
    loadData();
  }, [loadData, UUID, pageSize, pageType, galleryType, page, photographerName, modelName, tab, sortBy, loaded]);

  useEffect(() => {
    if (!isPrerender && pageType) {
      window.dataLayer.push({
        event: 'select_content',
        content_type: 'galleries',
        item_id: pageType,
        page: pageParam || 1,
      });
    }
  }, [pageType, pageParam]);

  const pageUrlPrefix = useMemo(() => {
    if (pageType === DEFAULT) {
      if (sortByParam === 'latest') {
        return '/galleries';
      }
      return `/galleries/${sortByParam}`;
    }
    if (pageType === PHOTOGRAPHER) {
      return `/photographer/${photographerName}/${tab}/${sortByParam}`;
    }
    if (pageType === MODEL) {
      return `/model/${modelName}/${tab}/${sortByParam}`;
    }
    if (pageType === PPS_GALLERIES) {
      return `/galleries/my/${sortByParam}`;
    }
    return '/galleries';
  }, [pageType, modelName, photographerName, sortByParam, tab]);

  const setPage = useCallback(
    (newPage) => {
      history.push(`${pageUrlPrefix}/${newPage}`);
    },
    [history, pageUrlPrefix],
  );

  const getSortUrl = useCallback(
    (value) => {
      if (pageType === DEFAULT) {
        const isLatest = value === 'latest';
        return `/galleries${isLatest ? '' : `/${value}`}`;
      }
      if (pageType === PHOTOGRAPHER) {
        return `/photographer/${photographerName}/${tab}/${value}`;
      }
      if (pageType === MODEL) {
        return `/model/${modelName}/${tab}/${value}`;
      }
      if (pageType === PPS_GALLERIES) {
        return `/galleries/my/${value}`;
      }
      return '';
    },
    [modelName, pageType, tab, photographerName],
  );

  const changeSort = useCallback(
    (value) => {
      const url = getSortUrl(value);
      if (url) {
        history.push(url);
      }

      setValue(sortSettingsKey, value);
    },
    [history, getSortUrl, sortSettingsKey],
  );

  useEffect(() => {
    if ((loaded && galleries.length === 0) || error?.status === 404) {
      history.replace(urls.get.notFound);
    }
  }, [loaded, galleries, error, history]);

  const renderGalleriesContent = () => {
    const totalPages = Math.ceil(itemCount / pageSize);

    // We change the visible cams based on the view type because the grids
    // will change size too
    const camsCount = galleryViewType === 'detailed' ? 12 : 14;

    return (
      <>
        {pageType === DEFAULT && (
          <Helmet
            id="galleries"
            variables={{
              siteName,
              galleriesCount: itemCount,
              siteDomain: domain,
              page: pageParam,
              firstPage: pageParam === 1,
            }}
          />
        )}
        <Div>
          {isPrerender && (
            <div>
              Sort By:{' '}
              <div>
                {SORT_TYPES.map((sortType) => {
                  const link = getSortUrl(sortType.id);
                  return (
                    <Link key={sortType.id} to={link}>
                      {' '}
                      <FormattedMessage id={sortType.intlID} defaultMessage={sortType.title} />
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
          {!props.hideViewOptions && !isPrerender && (
            <div className="options">
              <SortDropdown menuOptions={SORT_TYPES} value={sortByParam} onChange={changeSort} />
              <ConnectedViewOptions />
            </div>
          )}
          <GalleryCards galleries={galleries} camsCount={camsCount} showCams={!config.hasUpdates && cams.loaded} />
          <br />
          <div className="text-center">
            <Pagination
              activePage={pageParam || 1}
              boundaryRange={1}
              onPageChange={setPage}
              siblingRange={1}
              totalPages={totalPages}
              pageUrlPrefix={pageUrlPrefix}
              modal={<PaginationModal setPage={(pageNumber) => setPage(pageNumber)} totalPages={totalPages} />}
            />
          </div>
        </Div>
      </>
    );
  };

  const errorMarkup = error ? null : null; // Todo Create Content Error Container
  const galleriesContentMarkup = loaded ? (
    renderGalleriesContent()
  ) : (
    <LoaderWrapper detailed={galleryViewType === 'detailed'} />
  );
  return (
    <>
      {errorMarkup}
      {galleriesContentMarkup}
    </>
  );
}
