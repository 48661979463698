import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Pagination } from '@tovia/man-ui';
import { ContentListSection } from '../ContentListSection/ContentListSection';
import PaginationModal from 'src/client/components/modals/PaginationModal';
import { ModelResponse } from 'src/@types/app';
import { useSelector } from 'src/client/redux';
import LoaderWrapper from 'src/client/components/Loader/LoaderWrapper';
import { MODEL_GALLERIES_PER_PAGE } from 'src/shared/constants/models';

type Props = {
  item: ModelResponse;
  hideViewOptions?: boolean;
  loadData: () => void;
};

export const ModelLatest = (props: Props) => {
  const { item, error, loading } = useSelector((state) => state.model);
  const params = useParams<{ modelName?: string; page?: string }>();
  const { modelName, page = 1 } = params;
  const currentPage = Number(page);
  const pageUrlPrefix = `/model/${modelName}/latest`;
  const history = useHistory();

  const totalPages = Math.ceil((item?.latestGalleriesCount || MODEL_GALLERIES_PER_PAGE) / MODEL_GALLERIES_PER_PAGE);

  const setPage = useCallback(
    (newPage) => {
      history.push(`${pageUrlPrefix}/${newPage}`);
    },
    [history, pageUrlPrefix],
  );

  if (!item) return null;

  if (loading)
    return (
      <LoaderWrapper
        defaultHeight
        reloadFunction={props.loadData}
        loadingStatus={loading}
        reduxErrorStatus={error}
        contentType="model"
      />
    );

  return (
    <>
      <ContentListSection items={item.latestGalleries} hideViewOptions={props.hideViewOptions} />
      <Pagination
        activePage={currentPage}
        boundaryRange={1}
        onPageChange={setPage}
        siblingRange={1}
        totalPages={totalPages}
        pageUrlPrefix={pageUrlPrefix}
        modal={<PaginationModal setPage={setPage} totalPages={totalPages} />}
      />
    </>
  );
};
