import React from 'react';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { ModelResponse } from 'src/@types/app';
import { onEnterHandler } from 'src/client/helpers/onEnterHandler';
import { useSelector, useSettingsSelector } from 'src/client/redux';
import { displayModelStat } from 'src/client/utils/converters';
import { getModelAttributes } from '../modelAttributes';
import { ModelInfoPanel } from './ModelInfoPanel';
import {
  Attributes,
  Biography,
  WideModelPageContent,
  ModelBio,
  ModelBioTitle,
  StyledActivityPanel,
  StyledRankingDetailsPanel,
  WideModelLayout,
  WideModelWrapper,
  ANIMATION_NAME,
  ModelBioWrapper,
  ANIMATION_DURATION,
  BioComingSoon,
} from './styles';
import { useTransitionCalculator } from './useTransitionCalculator';

type Props = {
  model: ModelResponse;
  children: React.ReactNode;
};

export const WideModelPage = (props: Props) => {
  const { model } = props;
  const blogUrl = 'blogs' in model && model.blogs[0] ? model.blogs[0].path : null;

  const user = useSelector((state) => state.auth.user);
  const ratingsScale = useSettingsSelector<number>('ratingsScale');
  const measurements = useSettingsSelector('measurements');
  const config = useSelector((state) => state.app.config);

  const {
    gridCellRef,
    activeHeight,
    showComponent,
    hideComponent,
    onExitAnimationEnd,
    componentMounted: bioToggled,
    componentVisible: bioVisible,
  } = useTransitionCalculator();

  const modelBioStyle = {
    '--active-height': activeHeight,
  } as React.CSSProperties;

  const onToggleBio = () => (bioVisible ? hideComponent() : showComponent());

  const showRanking = config.leaderboard.active;

  const attributes = getModelAttributes(model);

  return (
    <WideModelWrapper>
      <WideModelLayout extended className={cx({ 'bio-toggled': bioToggled, 'is-member': !!user })}>
        <ModelInfoPanel model={props.model} bioVisible={bioVisible} onToggleBio={onToggleBio} />

        <StyledActivityPanel
          blogUrl={blogUrl}
          condensedView
          fullMode={user?.validSubscription}
          itemUrl={model.path}
          objectType="model"
          objectUUID={model.UUID}
          ratingAverage={parseFloat(model.ratingAverage.toFixed(ratingsScale))}
          ratings={model.ratingCount}
          views={model.views}
        />

        <CSSTransition
          classNames={ANIMATION_NAME}
          in={bioVisible}
          mountOnEnter
          onEnter={onEnterHandler}
          onExited={onExitAnimationEnd}
          timeout={ANIMATION_DURATION}
          unmountOnExit
        >
          <ModelBioWrapper style={modelBioStyle}>
            <ModelBio ref={gridCellRef}>
              <Attributes>
                <ModelBioTitle>{model.name}</ModelBioTitle>
                {attributes
                  .filter((attr) => !!attr.value)
                  .map(({ label, statType, value }) => (
                    <div key={statType}>
                      <strong>{label}: </strong>
                      <span>{displayModelStat({ statType, value, measurements })}</span>
                    </div>
                  ))}
              </Attributes>
              <Biography>
                <ModelBioTitle>Biography</ModelBioTitle>
                {model.biography ? model.biography : <BioComingSoon>Coming Soon</BioComingSoon>}
              </Biography>
            </ModelBio>
          </ModelBioWrapper>
        </CSSTransition>

        <WideModelPageContent>{props.children}</WideModelPageContent>

        {showRanking && <StyledRankingDetailsPanel ranks={model.ranks} type="model" className="model-ranking" />}
      </WideModelLayout>
    </WideModelWrapper>
  );
};
