import { io, Socket } from 'socket.io-client';
import { Store } from 'redux';
import { setUIValue } from '../redux/modules/settings';
import { addActivity, removeActivity } from '../redux/modules/activity';
import { addFavorites, removeFavorites } from '../redux/modules/favoriteInfo';
import { setRating, removeRating } from '../redux/modules/ratingInfo';
import { ClientToServerEvents, ServerToClientEvents } from 'src/@types/socket.io';

export type SocketIOClient = Socket<ServerToClientEvents, ClientToServerEvents>;

export const initSocketIO = (store: Store) => {
  const socket: SocketIOClient = io('', {
    path: '/ws',
    secure: true,
    transports: ['websocket'],
  });
  socket.on('settingsSetValue', ({ key, value }) => {
    store.dispatch(setUIValue({ key, value }));
  });

  socket.on('addActivity', (activity) => {
    store.dispatch(addActivity(activity));
  });

  socket.on('removeActivity', (activity) => {
    store.dispatch(removeActivity(activity));
  });

  socket.on('addFavorite', (UUID) => {
    store.dispatch(addFavorites([UUID]));
  });

  socket.on('removeFavorite', (UUID) => {
    store.dispatch(removeFavorites([UUID]));
  });

  socket.on('setRating', ({ objectUUID, rating }) => {
    store.dispatch(setRating({ UUID: objectUUID, rating }));
  });

  socket.on('removeRating', (UUID) => {
    store.dispatch(removeRating(UUID));
  });

  socket.on('emblemAgeVerification', () => {
    window.location.reload();
  });

  return socket;
};
