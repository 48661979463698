import {
  faEye,
  faFilm,
  faHeart,
  faSortAmountDown,
  faSortAmountUp,
  faStar,
  faThumbsUp,
  IconDefinition,
} from '@fortawesome/pro-light-svg-icons';

export interface SortType {
  id: string;
  title: string;
  order: string;
  direction: string;
  intlID: string;
  filter?: string;
}

export const SORT_TYPE_LATEST = {
  id: 'latest',
  title: 'Latest',
  order: 'DATE',
  direction: 'DESC',
  intlID: 'dropdown.latest', // corresponds to static/langs/X.json
};
export const SORT_TYPE_TOP_RATED = {
  id: 'top',
  title: 'Top Rated',
  order: 'RATING',
  direction: 'DESC',
  intlID: 'dropdown.topRated',
};
export const SORT_TYPE_MOST_VIEWED = {
  id: 'viewed',
  title: 'Most Viewed',
  order: 'VIEWS',
  direction: 'DESC',
  intlID: 'dropdown.mostViewed',
};
export const SORT_TYPE_MOST_FAVORITED = {
  id: 'favorited',
  title: 'Most Favorited',
  order: 'FAVORITES',
  direction: 'DESC',
  intlID: 'dropdown.mostFavorited',
};
export const SORT_TYPE_4K = {
  id: 'resolution',
  title: '4K Resolution',
  order: '4K',
  direction: 'DESC',
  intlID: 'dropdown.4kResolution',
  filter: 'has4kResolution',
};

export const SORT_TYPES: SortType[] = [
  SORT_TYPE_LATEST,
  SORT_TYPE_TOP_RATED,
  SORT_TYPE_MOST_VIEWED,
  SORT_TYPE_MOST_FAVORITED,
  SORT_TYPE_4K,
];

export const sortIcons: Record<SortType['id'], IconDefinition> = {
  favorited: faHeart,
  latest: faSortAmountDown,
  newest: faSortAmountUp,
  oldest: faSortAmountDown,
  resolution: faFilm,
  thumbsUp: faThumbsUp,
  top: faStar,
  viewed: faEye,
};
