import { useEffect, useRef, useState } from 'react';

export const useTransitionCalculator = () => {
  const gridCellRef = useRef<HTMLDivElement>(null);

  const [activeHeight, setActiveHeight] = useState('0px');
  const [componentMounted, setComponentMounted] = useState(false);
  const [componentVisible, setComponentVisible] = useState(false);

  const showComponent = () => {
    setComponentVisible(true);
    setComponentMounted(true);
  };

  const hideComponent = () => {
    setComponentVisible(false);
  };

  const onExitAnimationEnd = () => {
    setComponentMounted(false);
  };

  const updateHeight = () => {
    const newHeight = gridCellRef.current?.offsetHeight || 0;
    setActiveHeight(newHeight + 'px');
  };

  useEffect(() => {
    updateHeight();
  }, [componentMounted]);

  // add resize listener to keep the height updated on screen resize or device
  // orientation change
  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return {
    gridCellRef,
    activeHeight,
    showComponent,
    hideComponent,
    onExitAnimationEnd,
    componentMounted,
    componentVisible,
  };
};
