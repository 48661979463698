import React from 'react';
import { useDispatch } from 'react-redux';
import { useSettingsSelector } from 'src/client/redux';
import { sortIcons, SortType } from 'src/shared/constants/movies';
import { setValue } from 'src/client/redux/modules/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledIcon } from 'src/client/components/ui/StyledIcon';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { useHistory } from 'react-router';
import { DropdownButton, DropdownItem } from './styles';

type Props = {
  modelName?: string;
  onChange?: (value: string) => void;
  pageType?: string;
  photographerName?: string;
  sortSettingsKey: 'commentSorting' | 'galleriesSorting' | 'filmsSorting';
  sortTypes: SortType[];
  tab?: string;
};

export const IconOnlySortDropdown = (props: Props) => {
  const { sortSettingsKey, modelName, pageType, photographerName, tab, onChange } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const settingsValue = useSettingsSelector<string>(sortSettingsKey);

  const changeSort = (value: string) => () => {
    if (value === settingsValue) {
      return;
    }

    const url = getSortUrl({ value, pageType, modelName, photographerName, tab });
    if (url) {
      history.push(url);
    }

    onChange?.(value);
    dispatch(setValue(sortSettingsKey, value));
  };

  return (
    <DropdownButton
      title={
        <>
          <FontAwesomeIcon icon={sortIcons[settingsValue]} />
          <StyledIcon $spaceLeft icon={faCaretDown} />
        </>
      }
    >
      {props.sortTypes.map((sortType) => (
        <DropdownItem
          key={sortType.id}
          onClick={changeSort(sortType.id)}
          className={cx({ active: settingsValue === sortType.id })}
        >
          <StyledIcon $spaceRight icon={sortIcons[sortType.id]} />
          {sortType.title}
        </DropdownItem>
      ))}
    </DropdownButton>
  );
};

const DEFAULT = 'default';
const PPS_GALLERIES = 'pps';
const PHOTOGRAPHER = 'photographer';
const MODEL = 'model';

type GetSortUrlParams = {
  pageType?: string;
  value: string;
  modelName?: string;
  photographerName?: string;
  tab?: string;
};

const getSortUrl = (params: GetSortUrlParams) => {
  const { pageType, value, modelName, photographerName, tab } = params;
  const isLatest = value === 'latest';

  switch (pageType) {
    case DEFAULT:
      return `/galleries${isLatest ? '' : `/${value}`}`;
    case PPS_GALLERIES:
      return `/galleries/my/${value}`;
    case PHOTOGRAPHER:
      return `/photographer/${photographerName}/${tab}/${value}`;
    case MODEL:
      return `/model/${modelName}/${tab}/${value}`;
    default:
      return '';
  }
};
